import React, { useState } from "react";
import loginImg from "../../monkey.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export function Register ({
      switchScreen,
      ...props
    }){

  const {t, i18n} = useTranslation('common');
      
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState(undefined);
  const [password, setPassword] = useState(undefined);

  function onFirstNameChange(event) {
    setFirstName(event.target.value);
  }

  function onLastNameChange(event) {
    setLastName(event.target.value);
  }

  function onTermsAndPrivacyChange(event) {
    console.log('event', event);
    setTermsAndPrivacy(event.target.checked);
  }

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
  }

  function onSignUp() {
    if (!firstName) {
      toast.error(t('common.firstNameIsMissing'));
      return;
    }

    if (!lastName) {
      toast.error(t('common.lastNameIsMissing'));
      return;
    }

    if (!email) {
      toast.error(t('common.emailIsMissing'));
      return;
    }
    
    if (!password) {
      toast.error(t('common.passwordIsMissing'));
      return;
    }
    
    if (password.length < 8) {
      toast.error(t('common.passwordMustBeAtLestEight'));
      return;
    }
    console.log('termsAndPrivacy', termsAndPrivacy);
    if (!termsAndPrivacy) {
      toast.error(t('common.termsAndPrivacyMissing'));
      return;
    }

    // Post request to backend
    fetch(process.env.REACT_APP_API_URL + "/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        isSendPrivacyAndTerms: true,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.text().then((token) => {
            // save token in local storage and redirect.
            window.localStorage.setItem("token", token);
            window.location.href = process.env.REACT_APP_RESTAURANT_FRONTEND;
          });
        } else {
          res.json().then((json) => {
            toast.error(json.error);
          });
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div className="base-container" ref={props.containerRef}>
        <div className="content">
          <div className="image">
            <img src={loginImg} alt="ms logo" />
          </div>
          <div className="header">{t('register.title')}</div>
          <div className="form">
            <div className="form-group">
              <label htmlFor="name">{t('register.firstName')}</label>
              <input
                type="text"
                name="name"
                placeholder={t('register.firstName')}
                onChange={onFirstNameChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="surname">{t('register.lastName')}</label>
              <input
                type="text"
                name="surname"
                placeholder={t('register.lastName')}
                onChange={onLastNameChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">{t('register.email')}</label>
              <input
                type="email"
                name="email"
                placeholder={t('register.email')}
                onChange={onEmailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t('register.password')}</label>
              <input
                type="password"
                name="password"
                placeholder={t('register.password')}
                onChange={onPasswordChange}
              />
            </div>
            <div >
              <input type="checkbox" id="termsAndPrivacy" name="termsAndPrivacy" onChange={onTermsAndPrivacyChange}/>
              <label htmlFor="termsAndPrivacy">{t('register.byRegisteringYouAcceptOur')} <a href={t('register.termsOfServicesUrl')} target="_blank" rel="noreferrer">{t('register.termsOfServices')}</a> {t('common.and')} <a href={t('register.privacyPolicyUrl')} target="_blank" rel="noreferrer">{t('register.privacyPolicy')}</a></label>
            </div>
          </div>
          <div className="footer">
            <button type="button" className="main-btn" onClick={onSignUp}>
            {t('register.title')}
            </button>
          </div>
          <div className="footer">
            <button type="button" className="btn" onClick={() => switchScreen()}>
            {t('register.login')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
