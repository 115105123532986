import React, { useState } from "react";
import loginImg from "../../monkey.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export function PasswordReset({ showLoginScreen, resetKey, redirectTo, ...props }) { 
  
  const [password, setPassword] = useState(undefined);
  const [isLoginButtonNeeded, setLoginButtonNeeded] = useState(redirectTo);
  const [passwordConfirmation, setPasswordConfirmation] = useState(undefined);

  const {t, i18n} = useTranslation('common');

  function onPasswordChange(event) {
    setPassword(event.target.value);
  }

  function onPasswordConfirmationChange(event) {
    setPasswordConfirmation(event.target.value);
  }

  function onRequestReset() {
    if (!password) {
      toast.error(t('resetPassword.pleaseEnterNewPassword'));
      return;
    }

    if (!passwordConfirmation) {
      toast.error(t('resetPassword.pleaseEnterPasswordConfirmation'));
      return;
    }

    if (password !== passwordConfirmation) {
      toast.error(t('resetPassword.notMatch'));
    }

    // Post request to backend
    fetch(process.env.REACT_APP_API_URL + "/password-reset/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        key: resetKey
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.text().then( async (token) => {
            if (redirectTo) {
              window.location.href = redirectTo
            } else {
              toast.success(t('resetPassword.passwordReset'));
              showLoginScreen();
            }
          });
        } else {
          res.json().then((json) => {
            toast.error(json.error);
          });
        }
      })
      .catch((err) =>  {
        toast.error(err); 
        console.log(err)
      });
  }

  return (
    <>
      <ToastContainer />
      <div className="base-container" ref={props.containerRef}>
        <div className="content">
          <div className="image">
            <img src={loginImg} alt="ms logo" />
          </div>
          <div className="header">{t('resetPassword.title')}</div>
          <div className="form">
            <div className="form-group">
              <label htmlFor="password">{t('resetPassword.password')}</label>
              <input
                type="password"
                name="password"
                placeholder={t('resetPassword.password')}
                onChange={onPasswordChange}
              />
            </div>
          </div>
          <div className="form">
            <div className="form-group">
              <label htmlFor="passwordConfirmation">{t('resetPassword.passwordConfirmation')}</label>
              <input
                type="password"
                name="passwordConfirmation"
                placeholder={t('resetPassword.passwordConfirmation')}
                onChange={onPasswordConfirmationChange}
              />
            </div>
          </div>
          <div className="footer">
            <button type="button" className="main-btn" onClick={onRequestReset}>
            {t('resetPassword.title')}
            </button>
          </div>
          <div className="footer">
            <button type="button" className="btn" onClick={() => showLoginScreen()}>
            {t('login.backToLogin')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}