import React, { useState } from "react";
import loginImg from "../../monkey.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export function Login({ switchScreen, showPasswordRequestScreen, ...props }) { 
  
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);

  const {t, i18n} = useTranslation('common');

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onPasswordChange(event) {
    setPassword(event.target.value)
  }

  function onSignIn() {
    if (!email || !password) {
      toast.error(t('common.emailOrPasswordMissing'));
      return;
    }

    if (password.length < 8) {
      toast.error(t('common.passwordMustBeAtLestEight'));
      return;
    }

    // Post request to backend
    fetch(process.env.REACT_APP_API_URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.text().then((token) => {
            // save token in local storage and redirect.
            window.localStorage.setItem("token", token);
            window.location.href = process.env.REACT_APP_RESTAURANT_FRONTEND;
          });
        } else {
          res.json().then((json) => {
            toast.error(json.error);
          });
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div className="base-container" ref={props.containerRef}>
        <div className="content">
          <div className="image">
            <img src={loginImg} alt="ms logo" />
          </div>
          <div className="header">{t('login.title')}</div>
          <div className="form">
            <div className="form-group">
              <label htmlFor="email">{t('login.email')}</label>
              <input
                type="email"
                name="email"
                placeholder={t('login.email')}
                onChange={onEmailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t('login.password')}</label>
              <input
                type="password"
                name="password"
                placeholder={t('login.password')}
                onChange={onPasswordChange}
              />
            </div>
          </div>
          <div>
            <button type="button" className="reset-password-btn" onClick={() => showPasswordRequestScreen()}>
            {t('login.forgotPassword')}
            </button>
          </div>
          <div className="footer">
            <button type="button" className="main-btn" onClick={onSignIn}>
            {t('login.title')}
            </button>
          </div>
          { process.env.REACT_APP_IS_REGISTER_ENABLED === "true" ?
             <div className="footer">
              <button type="button" className="btn" onClick={() => switchScreen()}>
              {t('login.register')}
              </button>
            </div> 
            : ""
          }
         
        </div>
      </div>
    </>
  );
}