import React from "react";
import "./App.scss";
import { Login, Register, PasswordRequest, PasswordReset } from "./componenets/login/index";

class App extends React.Component {
  constructor(props) {
    // Store i18nextLng in localStorage
    localStorage.setItem('i18nextLng', navigator.language)

    if (window.localStorage.getItem("token")) {
      window.location.href = process.env.REACT_APP_RESTAURANT_FRONTEND
    }
    super(props);
    this.state = {
      isLogginActive: true,
      isPasswordRequestActive: false,
      isPasswordResetActive: false,
      resetKey: undefined
    };

    this.switchScreen = this.switchScreen.bind(this);
    this.showPasswordRequestScreen = this.showPasswordRequestScreen.bind(this);
    this.showLoginScreen = this.showLoginScreen.bind(this);

    if (window.location.pathname === '/password-reset/reset') {
      const key = new URLSearchParams(window.location.search).get("key");
      const redirectTo = new URLSearchParams(window.location.search).get("redirect_to");
      if (key) {
        console.log('show password reset screen!');
        this.state.isPasswordResetActive = true;
        this.state.resetKey = key;
        this.state.redirectTo = redirectTo;
      }
    }
  }

  
  changeState() {
    this.setState((prevState) => ({
      isLogginActive: !prevState.isLogginActive,
      isPasswordRequestActive: false
    }));
  }

  switchScreen() {
    this.setState((prevState) => ({
      isLogginActive: !prevState.isLogginActive,
      isPasswordRequestActive: false
    }));
  }

  showPasswordRequestScreen() {
    this.setState((prevState) => ({
      isPasswordRequestActive: true,
    }));
  }

  showLoginScreen() {
    this.setState((prevState) => ({
      isLogginActive: true,
      isPasswordRequestActive: false,
      isPasswordResetActive: false
    }));
  }

  render() {
    const { isLogginActive, isPasswordRequestActive, isPasswordResetActive, resetKey, redirectTo } = this.state;
    console.log('isLogginActive', isLogginActive);
    console.log('isPasswordRequestActive', isPasswordRequestActive);
    console.log('isPasswordResetActive', isPasswordResetActive);
    return (
      <div className="App">
        <div className="login">
          <div className="container" ref={(ref) => (this.container = ref)}>
            {isPasswordResetActive && (
                <PasswordReset
                  containerRef={(ref) => (this.current = ref)}
                  showLoginScreen={this.showLoginScreen}
                  resetKey={resetKey}
                  redirectTo={redirectTo}
                />
            )}
            {isLogginActive && !isPasswordRequestActive && !isPasswordResetActive && (
              <Login
                containerRef={(ref) => (this.current = ref)}
                switchScreen={this.switchScreen}
                showPasswordRequestScreen={this.showPasswordRequestScreen}
              />
            )}
            {!isLogginActive && !isPasswordRequestActive &&(
              <Register
                containerRef={(ref) => (this.current = ref)}
                switchScreen={this.switchScreen}
              />
            )}
            {isPasswordRequestActive && (
              <PasswordRequest
                containerRef={(ref) => (this.current = ref)}
                showLoginScreen={this.showLoginScreen}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
