import React, { useState } from "react";
import loginImg from "../../monkey.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export function PasswordRequest({ showLoginScreen, ...props }) { 
  
  const [email, setEmail] = useState(undefined);

  const {t, i18n} = useTranslation('common');

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onRequestReset() {
    if (!email) {
      toast.error(t('resetPassword.pleaseEnterEmail'));
      return;
    }

    // Post request to backend
    fetch(process.env.REACT_APP_API_URL + "/password-reset/request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.text().then((token) => {
            toast.success(t('resetPassword.emailOnItsWay'));
          });
        } else {
          res.json().then((json) => {
            toast.error(json.error);
          });
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div className="base-container" ref={props.containerRef}>
        <div className="content">
          <div className="image">
            <img src={loginImg} alt="ms logo" />
          </div>
          <div className="header">{t('resetPassword.title')}</div>
          <div className="form">
            <div className="form-group">
              <label htmlFor="email">{t('resetPassword.email')}</label>
              <input
                type="email"
                name="email"
                placeholder={t('resetPassword.email')}
                onChange={onEmailChange}
              />
            </div>
          </div>
          <div className="footer">
            <button type="button" className="main-btn" onClick={onRequestReset}>
            {t('resetPassword.title')}
            </button>
          </div>
          <div className="footer">
            <button type="button" className="btn" onClick={() => showLoginScreen()}>
            {t('login.title')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}